exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bike-js": () => import("./../../../src/pages/bike.js" /* webpackChunkName: "component---src-pages-bike-js" */),
  "component---src-pages-car-js": () => import("./../../../src/pages/car.js" /* webpackChunkName: "component---src-pages-car-js" */),
  "component---src-pages-gold-js": () => import("./../../../src/pages/gold.js" /* webpackChunkName: "component---src-pages-gold-js" */),
  "component---src-pages-home-construction-js": () => import("./../../../src/pages/home-construction.js" /* webpackChunkName: "component---src-pages-home-construction-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-student-education-js": () => import("./../../../src/pages/student-education.js" /* webpackChunkName: "component---src-pages-student-education-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

